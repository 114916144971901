<template>
  <div :class="isModalActive ? 'blur-content' : ''" class="row g-5 print-hidden">
    <div class="col-12 mb-5">
      <div class="card">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">
              <i
                class="bi bi-arrow-left cursor-pointer align-middle fs-1 me-3"
                @click="this.$router.push({ name: 'biblios' })"
              ></i>
              Ma bibliothèque
            </span>
          </h3>
        </div>
        <div class="card-body w-100 row pt-2">
          <div class="col-12">
            <div class="form-group mb-3">
              <label class="text-muted">Nom de la bibliothèque</label>
              <input
                v-model="this.localBiblio.name"
                class="form-control form-control-lg form-control-solid"
                type="text"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3">
              <TagMultiSelect
                v-model="this.localBiblio.tags"
                for="BIBLIO"
                @newTag="modalCreateTagShow"
              ></TagMultiSelect>
            </div>
          </div>
          <div class="flex-column text-end">
            <!--:disabled="this.biblio.type === 'DEFAULT'"-->
            <button
              :disabled="!this.leaveGuard || this.biblio.type === 'DEFAULT'"
              class="btn btn-primary btn-active-accent"
              @click="this.saveBiblio()"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="this.biblio.type === 'DEFAULT'">
    <div class="alert bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row p-5 mb-3">
      <div class="">
        <span class="bi bi-lightbulb text-primary me-4 fs-1 align-middle"></span>
      </div>
      <div class="d-flex flex-column pe-0 pe-sm-10">
        <h5 class="mb-1">Bibliothèque by Kalm</h5>
        <span>
          Ceci est une bibliothèque conçue par nos experts. Bien que l'on soit très fiers de la qualité du contenu, on
          vous rappelle que vous restez seuls responsables des pièces écrites que vous rédigez avec Kalm. Par ailleurs,
          vous ne pouvez pas la modifier directement... mais vous pouvez librement en copier le contenu dans une
          nouvelle bibliothèque personnelle,
          <router-link :to="{ name: 'biblios' }">depuis la page Bibliothèques</router-link>.
        </span>
      </div>
      <button
        class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
        data-bs-dismiss="alert"
        type="button"
      >
        <i class="bi bi-x fs-1"></i>
      </button>
    </div>
  </div>
  <div :class="isModalActive ? 'blur-content' : ''" class="container p-0">
    <div class="editor row g-0">
      <!-- EDITBAR FIXED -->
      <div
        v-if="editor && incrementDummy"
        v-observe-visibility="this.onElementVisibility"
        class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100"
      >
        <div
          class="print-hidden editor-toolbar toolbar-top px-7 d-flex justify-content-between align-items-center w-100 mt-0"
        >
          <div>
            <div v-tooltip:bottom="'Mode réduit'" class="d-flex" title="Mode réduit">
              <div class="my-auto pt-1 form-check form-switch form-switch-sm">
                <input
                  id="descriptionDisplayModeToggle"
                  v-model="isDescriptionDisplayModeCollapsed"
                  class="form-check-input border-white"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
          <div class="px-5">
            <button
              v-tooltip:bottom="'Annuler'"
              :disabled="!editor.can().chain().focus().undo().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().undo().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-arrow-counterclockwise fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Refaire'"
              :disabled="!editor.can().chain().focus().redo().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().redo().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-arrow-clockwise fs-2 p-0"></i>
              </span>
            </button>
            <span class="text-muted mx-2 my-auto">|</span>
            <button
              v-tooltip:bottom="'Importer depuis un Excel'"
              class="btn btn-color-white mx-1 p-2"
              @click="modalImportFromExcelShow()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-cloud-arrow-up fs-3 p-0"></i>
              </span>
            </button>
            <span class="text-muted mx-2 my-auto">|</span>
            <button
              v-tooltip:bottom="'Titre'"
              :disabled="!editor.can().chain().focus().toggleHeading({ level: 4 }).run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-h1 fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Paragraphe'"
              :disabled="!editor.can().chain().focus().toggleNode('paragraph', 'text').run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleNode('paragraph', 'text').run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-justify-left fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Gras'"
              :class="{ 'is-active': editor.isActive('bold') }"
              :disabled="!editor.can().chain().focus().toggleBold().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleBold().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-bold fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Italique'"
              :class="{ 'is-active': editor.isActive('italic') }"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleItalic().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-italic fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Sous-ligner'"
              :class="{ 'is-active': editor.isActive('underline') }"
              :disabled="!editor.can().chain().focus().toggleUnderline().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleUnderline().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-underline fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Barrer'"
              :class="{ 'is-active': editor.isActive('strike') }"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleStrike().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-strikethrough fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" @click="this.menuColorToggle()">
              <span
                :style="'border-bottom: 2px solid' + (editor.getAttributes('textStyle').color || '#000000')"
                class="text-center"
              >
                <i class="bi bi-text-alphabet fs-2 p-0 pb-0 mb-0" style="font-style: normal">A</i>
              </span>
            </button>
            <span class="text-muted mx-2 my-auto">|</span>
            <button
              v-tooltip:bottom="'Liste à puces'"
              :class="{ 'is-active': editor.isActive('strike') }"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleBulletList().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-list-ul fs-2 p-0"></i>
              </span>
            </button>
          </div>
          <div>
            <button
              :disabled="!this.leaveGuard || this.biblio.type === 'DEFAULT'"
              class="btn align-baseline btn-color-white mx-1 p-2"
              @click="this.saveBiblio()"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
      <!-- EDITBAR STICKY -->
      <div
        v-if="editor && this.isEditorScrolledDown"
        class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100 px-5 editor-buttons-sticky"
      >
        <div
          class="print-hidden editor-toolbar toolbar-sticky px-7 d-flex d-flex justify-content-between align-items-center w-100 mt-0"
        >
          <div>
            <div v-tooltip:bottom="'Mode réduit'" class="d-flex" title="Mode réduit">
              <div class="my-auto pt-1 form-check form-switch form-switch-sm">
                <input
                  id="descriptionDisplayModeToggle"
                  v-model="isDescriptionDisplayModeCollapsed"
                  class="form-check-input border-white"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
          <div class="px-5">
            <button
              v-tooltip:bottom="'Annuler'"
              :disabled="!editor.can().chain().focus().undo().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().undo().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-arrow-counterclockwise fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Refaire'"
              :disabled="!editor.can().chain().focus().redo().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().redo().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-arrow-clockwise fs-2 p-0"></i>
              </span>
            </button>
            <span class="text-muted mx-2 my-auto">|</span>
            <button
              v-tooltip:bottom="'Importer depuis un Excel'"
              class="btn btn-color-white mx-1 p-2"
              @click="modalImportFromExcelShow()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-cloud-arrow-up fs-3 p-0"></i>
              </span>
            </button>
            <span class="text-muted mx-2 my-auto">|</span>
            <button
              v-tooltip:bottom="'Titre'"
              :disabled="!editor.can().chain().focus().toggleHeading({ level: 4 }).run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-h1 fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Paragraphe'"
              :disabled="!editor.can().chain().focus().toggleNode('paragraph', 'text').run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleNode('paragraph', 'text').run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-justify-left fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Gras'"
              :class="{ 'is-active': editor.isActive('bold') }"
              :disabled="!editor.can().chain().focus().toggleBold().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleBold().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-bold fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Italique'"
              :class="{ 'is-active': editor.isActive('italic') }"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleItalic().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-italic fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Sous-ligner'"
              :class="{ 'is-active': editor.isActive('underline') }"
              :disabled="!editor.can().chain().focus().toggleUnderline().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleUnderline().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-underline fs-2 p-0"></i>
              </span>
            </button>
            <button
              v-tooltip:bottom="'Barrer'"
              :class="{ 'is-active': editor.isActive('strike') }"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleStrike().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-type-strikethrough fs-2 p-0"></i>
              </span>
            </button>
            <button class="btn btn-color-white mx-1 p-2" @click="this.menuColorToggle()">
              <span
                :style="'border-bottom: 2px solid' + (editor.getAttributes('textStyle').color || '#000000')"
                class="text-center"
              >
                <i class="bi bi-text-alphabet fs-2 p-0 pb-0 mb-0" style="font-style: normal">A</i>
              </span>
            </button>
            <span class="text-muted mx-2 my-auto">|</span>
            <button
              v-tooltip:bottom="'Liste à puces'"
              :class="{ 'is-active': editor.isActive('strike') }"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              class="btn btn-color-white mx-1 p-2"
              @click="editor.chain().focus().toggleBulletList().run()"
            >
              <span class="text-center align-middle">
                <i class="bi bi-list-ul fs-2 p-0"></i>
              </span>
            </button>
          </div>
          <div>
            <button
              :disabled="!this.leaveGuard || this.biblio.type === 'DEFAULT'"
              class="btn align-baseline btn-color-white mx-1 p-2"
              @click="this.saveBiblio()"
            >
              Enregistrer
            </button>
          </div>
        </div>
        <div v-if="this.menuColor.show" class="card shadow p-3" style="position: absolute; top: 50px">
          <div class="card-body p-1">
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor1 || '#000000' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor1 || `#000000`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor1 || '#000000')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor2 || '#195D5B' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor2 || `#195D5B`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor2 || '#195D5B')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor3 || '#3C78D8' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor3 || `#3C78D8`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor3 || '#3C78D8')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor4 || '#A64D79' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor4 || `#A64D79`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor4 || '#A64D79')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor5 || '#CC0000' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor5 || `#CC0000`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor5 || '#CC0000')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor6 || '#F1C232' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor6 || `#F1C232`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor6 || '#F1C232')
                  .run()
              "
            ></button>
            <i
              class="bi bi-x text-gray-800 text-hover-primary cursor-pointer align-middle fs-1"
              @click="this.menuColorToggle()"
            ></i>
          </div>
        </div>
      </div>
      <!-- EDITBAR TOOLTIPS -->
      <div
        v-if="editor && !this.isEditorScrolledDown"
        class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100 px-5 editor-buttons-sticky"
      >
        <div v-if="this.menuColor.show" class="card shadow p-3" style="position: absolute; top: 10px">
          <div class="card-body p-1">
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor1 || '#000000' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor1 || `#000000`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor1 || '#000000')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor2 || '#195D5B' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor2 || `#195D5B`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor2 || '#195D5B')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor3 || '#3C78D8' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor3 || `#3C78D8`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor3 || '#3C78D8')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor4 || '#A64D79' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor4 || `#A64D79`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor4 || '#A64D79')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor5 || '#CC0000' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor5 || `#CC0000`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor5 || '#CC0000')
                  .run()
              "
            ></button>
            <button
              :class="{
                'is-active': editor.isActive('textStyle', { color: this.currentUser.org.persoColor6 || '#F1C232' }),
              }"
              :style="`background-color: ${this.currentUser.org.persoColor6 || `#F1C232`}; height: 20px; width: 20px;`"
              class="btn btn-icon rounded-circle m-1"
              @click="
                this.editor
                  .chain()
                  .focus()
                  .setColor(this.currentUser.org.persoColor6 || '#F1C232')
                  .run()
              "
            ></button>
            <i
              class="bi bi-x text-gray-800 text-hover-primary cursor-pointer align-middle fs-1"
              @click="this.menuColorToggle()"
            ></i>
          </div>
        </div>
      </div>
      <div v-if="editor" class="form__item form__item--title">
        <PrintHeader
          v-if="this.editor && this.currentFocus"
          pageTitle="Descriptif Travaux"
          sectionTitle=""
        ></PrintHeader>
        <div v-if="this.editor && this.currentFocus">
          <FloatingMenu
            :key="this.floatingMenuKey"
            :editor="this.editor"
            :should-show="this.shouldShowFloatingMenu"
            :tippy-options="{}"
            :v-if="this.editor"
            class="floating-menu print-hidden"
          >
            <button @click="editor.chain().focus().insertContent('<ouvrage><h4></h4></ouvrage>').run()">
              + Bloc Ouvrage
            </button>
            <button @click="editor.chain().focus().insertContent('<lot-text><p></p></lot-text>').run()">
              + Bloc Texte
            </button>
            <!--                        <button @click="this.modalImportShow()">
                            <span class="me-2 bi bi-recycle"></span>Importer
                        </button>-->
          </FloatingMenu>
        </div>
        <div class="p-10 overflow-visible">
          <editor-content
            v-if="this?.editor && this?.incrementDummy"
            :key="this.floatingMenuKey"
            :editor="this.editor"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - Import from project -->

  <!--    <div class="modal"
         tabindex="-1"
         :style="this.modals.import.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        &lt;!&ndash;begin::Close&ndash;&gt;
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="modalImportClose()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        &lt;!&ndash;end::Close&ndash;&gt;
                    </div>

                    <div class="modal-body p-0">
                        &lt;!&ndash;begin::Stepper&ndash;&gt;
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            &lt;!&ndash;begin::Content&ndash;&gt;
                            <div class="d-flex flex-row-fluid justify-content-center">
                                &lt;!&ndash;begin::Form&ndash;&gt;
                                <div v-if="this.modals.import.page === 0" ref="contactEditModalForm"
                                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    &lt;!&ndash;begin::Step&ndash;&gt;
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            &lt;!&ndash;begin::Heading&ndash;&gt;
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                                                <p class="text-muted">Choisissez le lot d'un projet ou gabarit dont vous
                                                    voulez importer le contenu :</p>
                                            </div>
                                            &lt;!&ndash;end::Heading&ndash;&gt;

                                            &lt;!&ndash;begin::Project Selection&ndash;&gt;
                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-1 fw-bolder text-dark form-label">Projet</label>
                                                <select v-model="this.modals.import.projectId"
                                                        class="form-select form-select-solid">
                                                    <option
                                                        v-for="project in [{id: '', name: 'Sélectionnez un projet...', disabled: true}, ...this.getProjects]"
                                                        :disabled="project.disabled" :key="project.id"
                                                        :value="project.id">{{ project.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            &lt;!&ndash;end::Project Selection&ndash;&gt;

                                            &lt;!&ndash;begin::Lot Selection&ndash;&gt;
                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-1 fw-bolder text-dark form-label">Lot</label>
                                                <select v-model="this.modals.import.lotId"
                                                        class="form-select form-select-solid">
                                                    <option
                                                        v-for="lot in [{id: '', fullName: 'Sélectionnez un lot...', disabled: true}, ...this.modalImportGetLotsForProject()]"
                                                        :disabled="lot.disabled" :key="lot.id" :value="lot.id">
                                                        {{ lot.fullName }}
                                                    </option>
                                                </select>
                                            </div>
                                            &lt;!&ndash;end::Lot Selection&ndash;&gt;
                                        </div>
                                    </div>
                                    &lt;!&ndash;end::Step&ndash;&gt;

                                    &lt;!&ndash;begin::Actions&ndash;&gt;
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="this.modalImportClose()">
                                                Annuler
                                            </button>
                                            <button class="btn btn-lg btn-primary ms-2"
                                                    @click="this.modalImportFetchOuvrages()"
                                                    :disabled="!this.modals.import.projectId || !this.modals.import.lotId"
                                            >
                                                Suivant
                                            </button>
                                        </div>
                                    </div>
                                    &lt;!&ndash;end::Actions&ndash;&gt;
                                </div>
                                &lt;!&ndash;end::Form&ndash;&gt;
                                &lt;!&ndash;begin::Form&ndash;&gt;
                                <div v-if="this.modals.import.page === 1"
                                     ref="contactEditModalForm2"
                                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    &lt;!&ndash;begin::Step&ndash;&gt;
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            &lt;!&ndash;begin::Heading&ndash;&gt;
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                                                <p v-if="this.modals.import.ouvrages.length > 0" class="text-muted">
                                                    Sélectionnez les ouvrages à importer :</p>
                                                <p v-else class="text-muted">Aucun ouvrage à importer</p>
                                            </div>
                                            &lt;!&ndash;end::Heading&ndash;&gt;

                                            <div>
                                                <div v-for="item in this.modals.import.ouvrages" :key="item">
                                                    <div
                                                        v-if="item.type === 'lotOuvrage' && item?.content && item.content.length > 0"
                                                        class="row">
                                                        <div class="col-2 align-middle checkbox">
                                                            <input type="checkbox" class="form-check-input align-middle"
                                                                   :value="item"
                                                                   v-model="this.modals.import.selectedOuvrages">
                                                        </div>
                                                        <div class="col-10">
                                                            <p v-if="item.content[0]?.content && item.content[0].content.length > 0 && item.content[0]?.content[0]?.text"
                                                               class="text-black fw-bold mb-0">Ouvrage :
                                                                {{ item.content[0]?.content[0]?.text }}</p>
                                                            <p v-if="item.content[1]?.content && item.content[1].content.length > 0 && item.content[1]?.content[0]?.text"
                                                               class="text-muted">{{
                                                                    item.content[1]?.content[0]?.text
                                                                }}<span
                                                                    v-if="item.content.length > 1 || item.content[1].content.length > 0">...</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else-if="item.type === 'lotText' && item?.content[0]?.content"
                                                        class="row">
                                                        <div class="col-2 align-middle checkbox">
                                                            <input type="checkbox" class="form-check-input align-middle"
                                                                   :value="item"
                                                                   v-model="this.modals.import.selectedOuvrages">
                                                        </div>
                                                        <div class="col-10">
                                                            <p class="text-black fw-bold mb-0">Bloc texte :
                                                                {{ item.content[0].content[0].text }}<span
                                                                    v-if="item.content[0].content[1]">...</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &lt;!&ndash;end::Step&ndash;&gt;

                                    &lt;!&ndash;begin::Actions&ndash;&gt;
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="modalImportShow()">
                                                Précédent
                                            </button>
&lt;!&ndash;                                            <button class="btn btn-lg btn-primary ms-2"
                                                    @click="modalImportValidate()"
                                                    :disabled="!this.modals.import.projectId || !this.modals.import.lotId"
                                            >
                                                Importer
                                            </button>&ndash;&gt;
                                        </div>
                                    </div>
                                    &lt;!&ndash;end::Actions&ndash;&gt;
                                </div>
                                &lt;!&ndash;end::Form&ndash;&gt;
                            </div>
                            &lt;!&ndash;end::Content&ndash;&gt;
                        </div>
                        &lt;!&ndash;end::Stepper&ndash;&gt;
                    </div>
                </div>
            </div>
        </div>
    </div>-->
  <!-- MODAL - Import from Excel -->
  <div
    :style="this.modals.importFromExcel.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'"
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalImportFromExcelClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <div
                  v-if="this.modals.importFromExcel.page === 0"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                        <p class="text-muted">
                          Importez un fichier Excel contenant les ouvrages à ajouter. La première ligne doit contenir
                          les intitulés de colonnes.
                        </p>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            accept=".xls,.xlsx"
                            class="form-control"
                            type="file"
                            @change="this.modalImportFromExcelFileChange($event)"
                          />
                        </div>
                        <!--                        <p class="col-12 text-muted my-2 text-center">— Ou —</p>
                                                                        <div class="col-12 d-flex">
                                                                          <textarea type="text" placeholder="Collez le tableau ici" class="form-control"
                                                                                 v-model="this.modals.importFromExcel.clipboardData"
                                                                                    @change="this.modalImportFromExcelClipboardChange()"></textarea>
                                                                          <span class="ms-3 align-self-center"><i v-tooltip:bottom="'Pour des ouvrages simples, sans description multi-lignes'" class="bi fs-3 bi-info-circle"></i></span>
                                                                        </div>-->
                      </div>
                      <!--end::Heading-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="this.modalImportFromExcelClose()">Annuler</button>
                      <button
                        :disabled="this.modals.importFromExcel.mode === 'start'"
                        class="btn btn-lg btn-primary ms-2"
                        @click="
                          this.modals.importFromExcel.page = 1;
                          this.modalImportFromExcelFetchColumns();
                        "
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <div
                  v-if="this.modals.importFromExcel.page === 1"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                        <p class="text-muted">Attribuez chaque colonne du tableur au contenu des blocs ouvrages.</p>
                        <p class="text-muted">
                          Astuce : nommez les colonnes de votre tableur Excel comme ci-dessous (titre, description...)
                          pour que les colonnes soient reconnues automatiquement !
                        </p>
                      </div>
                      <div class="row">
                        <div
                          v-for="attribute in this.modals.importFromExcel.possibleColumns"
                          :key="attribute.id"
                          class="form-group mb-3"
                        >
                          <label class="form-label text-muted"
                            >{{ attribute.label
                            }}<span v-if="attribute.required" class="ms-1 fs-6">(Obligatoire)</span></label
                          >
                          <select v-model="this.modals.importFromExcel.columnMap[attribute.id]" class="form-select">
                            <option :value="null">Aucun</option>
                            <option
                              v-for="(colName, index) in this.modals.importFromExcel.importColumns"
                              :key="colName"
                              :value="index"
                            >
                              {{ colName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="this.modalImportFromExcelClose()">Annuler</button>
                      <button
                        :disabled="!this.modalImportFromExcelCanValidate()"
                        class="btn btn-lg btn-primary ms-2"
                        @click="this.modalImportFromExcelValidate()"
                      >
                        Lancer l'import
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateTagModal
    v-if="modals.createTag.show"
    v-model="modals.createTag.newTag"
    @close="modalCreateTagClose"
    @validate="modalCreateTagValidate"
  />
</template>

<script>
import kalmStore from "@/store";

import PrintHeader from "@/views/project/tender/PrintHeader";
//import writeXlsxFile from 'write-excel-file'

import { debounce } from "@/utils/debounce";

import StarterKit from "@tiptap/starter-kit";
import { FloatingMenu, Editor, EditorContent } from "@tiptap/vue-3";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Underline } from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";

import { biblioParagraph, biblioOuvrage, biblioText } from "./BiblioDoc";
import { biblioDoc } from "@/views/project/biblio/BiblioDoc";
import { customDropCursor } from "../description/DocContentNodes";
import TagMultiSelect from "@/views/tags/presentation/tag-select/TagMultiSelect.vue";
import CreateTagModal from "@/views/tags/presentation/components/CreateTagModal.vue";

import { Paragraph } from "@tiptap/extension-paragraph";
import Link from "@tiptap/extension-link";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { CURRENT_USER_QUERY, GET_BIBLIO_BY_ID, MUTATION_BIBLIO_UPDATE } from "@/graphql/graphql";
import { CREATE_TAG, GET_TAGS } from "@/views/tags/data/tags_graphql";

import readXlsxFile from "read-excel-file";

export default {
  name: "BiblioEditPage",
  components: {
    FloatingMenu,
    EditorContent,
    PrintHeader,
    TagMultiSelect,
    CreateTagModal,
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    biblio: {
      query: GET_BIBLIO_BY_ID,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        let ret = JSON.parse(JSON.stringify(data.biblio));

        ret.document = data.biblio.document ? JSON.parse(data.biblio.document) : null;

        this.localBiblio.name = ret.name;
        this.localBiblio.document = ret.document;
        this.localBiblio.tags = ret.tags.map((t) => t.id);

        return ret;
      },
    },
    /*project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: this.$route.params.projectId
                }
            }
        }*/
  },
  data() {
    return {
      incrementDummy: 1,
      biblio: {
        document: null,
        lots: [],
      },
      localBiblio: {
        name: "",
        document: {},
        tags: [],
      },
      currentUser: {},
      menuColor: {
        show: false,
      },
      editor: null,
      isEditorScrolledDown: false,
      leaveGuard: false,
      modals: {
        print: {
          show: false,
          lots: [],
        },
        import: {
          page: 0,
          show: false,
          projectId: "",
          lotId: "",
          ouvrages: [],
          selectedOuvrages: [],
        },
        importFromExcel: {
          show: false,
          page: 0,
          clipboardData: "",
          fileData: null,
          mode: "clipboard",
          columnMap: {},
          possibleColumns: [
            { label: "Titre", id: "title", required: true },
            { label: "Description", id: "description" },
            /*{ label: 'Lot', id: 'lot'},*/
            /*{ label: 'Emplacement(s)', id: 'locations'},*/
            { label: "Prix unitaire", id: "unitPrice" },
            { label: "Quantité", id: "qt" },
            { label: "Unité", id: "unit" },
          ],
          importColumns: [],
        },
        createTag: {
          show: false,
          callback: null,
          newTag: {
            name: "",
            color: "",
          },
        },
      },
      floatingMenuKey: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.leaveGuard) {
      if (window.confirm("Vous avez des changements non sauvegardés. Êtes-vous sûr de vouloir quitter ?")) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    modalCreateTagShow(callback) {
      this.modals.createTag.show = true;
      this.modals.createTag.callback = callback;
    },
    async modalCreateTagValidate() {
      try {
        const tag = await this.$apollo.mutate({
          mutation: CREATE_TAG,
          variables: {
            name: this.modals.createTag.newTag.name,
            color: this.modals.createTag.newTag.color,
            entity: "BIBLIO",
          },
          refetchQueries: [{ query: GET_TAGS, variables: { entity: "BIBLIO" } }],
        });
        this.modals.createTag.callback(tag.data.tagCreate.tag.id);
        this.modalCreateTagClose();
        toast.success("Le tag a bien été créé");
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue lors de la création du tag");
      }
    },
    modalCreateTagClose() {
      this.modals.createTag.show = false;
      this.modals.createTag.newTag.name = "";
      this.modals.createTag.newTag.color = "";
    },
    saveBiblio() {
      this.$apollo
        .mutate({
          mutation: MUTATION_BIBLIO_UPDATE,
          variables: {
            id: this.biblio.id,
            document: this.localBiblio.document,
            name: this.localBiblio.name,
            tags: this.localBiblio.tags,
          },
        })
        .catch(() => {
          toast.error("Erreur lors de l'enregistrement de la bibliothèque, contactez le service client");
        })
        .then(() => {
          this.leaveGuard = false;
          toast.success("Bibliothèque enregistrée");
        });
    },
    onElementVisibility(state) {
      this.isEditorScrolledDown = !state;
    },
    scrollMeTo(lotId) {
      setTimeout(function () {
        document.querySelector(lotId).scrollIntoView({ behavior: "smooth" });
      }, 2000);
    },
    fetchFocusDoc() {
      const doc = this.localBiblio.document;
      let newDoc;

      if (doc && doc?.type === "biblioDoc") {
        newDoc = doc;
      } else {
        newDoc = {
          type: "biblioDoc",
          content: [
            {
              type: "lotParagraph",
              content: [
                {
                  type: "text",
                  text: "Ajoutez des blocs ouvrage ou texte ci-dessous pour remplir la bibliothèque. Vous pourrez ensuite les réutiliser dans vos projets !",
                },
              ],
            },
          ],
        };
      }
      return newDoc;
    },
    shouldShowFloatingMenu({ state }) {
      return (
        state.selection.$anchor.parent.type.name === "lotParagraph" &&
        state.selection.$anchor.parent.content.size === 0 &&
        state.selection.$anchor.nodeBefore === null &&
        state.selection.$anchor.nodeAfter === null
      );
    },
    syncDocument(newJSONDoc) {
      // local biblio doc save
      this.localBiblio.document = newJSONDoc;
      this.leaveGuard = true;
    },
    printDocument() {
      this.$nextTick(function () {
        window.print();
      });
    },
    modalImportFromExcelShow() {
      this.modals.importFromExcel.show = true;
      this.modals.importFromExcel.mode = "start";
      this.modals.importFromExcel.columnMap = {};
      this.modals.importFromExcel.clipboardData = "";
      this.modals.importFromExcel.importColumns = null;
      this.modals.importFromExcel.page = 0;
    },
    modalImportFromExcelClose() {
      this.modals.importFromExcel.show = false;
      this.modals.importFromExcel.mode = "start";
      this.modals.importFromExcel.page = 0;
    },
    modalImportFromExcelValidate() {
      const m = this.modals.importFromExcel.columnMap;

      if (this.modals.importFromExcel.mode === "file") {
        this.modals.importFromExcel.fileData.forEach((l) => {
          // TODO Add try/catch and toast error

          this.editor.commands.insertContent({
            type: "lotOuvrage",
            attrs: {
              locations: l[m["locations"]] || [],
              qt: l[m["qt"]],
              unit: (l[m["unit"]] || "").toLowerCase(),
              unitPrice: l[m["unitPrice"]],
            },
            content: [
              {
                type: "heading",
                attrs: {
                  level: 4,
                },
                content: [
                  {
                    type: "text",
                    text: l[m["title"]],
                  },
                ],
              },
              ...(l[m["description"]] || "").split("\n").map((p) => {
                if (p !== "") {
                  return {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: p,
                      },
                    ],
                  };
                } else {
                  return {
                    type: "paragraph",
                  };
                }
              }),
            ],
          });
        });

        toast.success(
          "" +
            this.modals.importFromExcel.fileData.length +
            (this.modals.importFromExcel.fileData.length > 1 ? " ouvrages importés" : " ouvrage importé")
        );
      } else if (this.modals.importFromExcel.mode === "clipboard") {
        // do stuff
      } else {
        // error
      }

      this.modals.importFromExcel.show = false;
    },
    modalImportFromExcelCanValidate() {
      let ret = true;
      this.modals.importFromExcel.possibleColumns
        .filter((c) => c.required)
        .forEach((c) => {
          if (this.modals.importFromExcel.columnMap[c.id] === null) {
            ret = false;
          }
        });
      return ret;
    },
    modalImportFromExcelClipboardChange() {
      if (this.modals.importFromExcel.clipboardData) {
        this.modals.importFromExcel.mode = "clipboard";
      } else {
        if (!this.modals.importFromExcel.fileData) {
          this.modals.importFromExcel.mode = "start";
        }
      }
    },
    modalImportFromExcelFileChange($event) {
      if ($event.target.files[0]) {
        readXlsxFile($event.target.files[0]).then((rows) => {
          this.modals.importFromExcel.fileData = rows;
          this.modals.importFromExcel.mode = "file";
        });
      } else {
        if (!this.modals.importFromExcel.clipboardData) {
          this.modals.importFromExcel.mode = "start";
        }
      }
    },
    modalImportFromExcelFetchColumns() {
      if (this.modals.importFromExcel.mode === "file") {
        this.modals.importFromExcel.importColumns = this.modals.importFromExcel.fileData[0];
        this.modals.importFromExcel.fileData = this.modals.importFromExcel.fileData.splice(1);
      } else if (this.modals.importFromExcel.mode === "clipboard") {
        this.modals.importFromExcel.importColumns = this.modals.importFromExcel.clipboardData
          .split("\n")[0]
          .split("\t");
      }
      this.modals.importFromExcel.possibleColumns.forEach((c) => {
        this.modals.importFromExcel.columnMap[c.id] = null;
        this.modals.importFromExcel.importColumns.forEach((col, index) => {
          if (col.toLowerCase().includes(c.label.toLowerCase())) {
            this.modals.importFromExcel.columnMap[c.id] = index;
          }
        });
      });
    },
    loadEditor() {
      this.floatingMenuKey++;
      this.editor = new Editor({
        editable: this.biblio.type === "PRIVATE",
        extensions: [
          biblioDoc,
          biblioParagraph,
          biblioOuvrage,
          biblioText,
          Underline,
          TextStyle,
          Color,
          Link,
          StarterKit.configure({
            document: false,
            dropcursor: false,
            paragraph: false,
          }),
          Placeholder.configure({
            includeChildren: true,
            showOnlyCurrent: false,
            placeholder: ({ node }) => {
              switch (node.type.name) {
                case "heading":
                  return "Titre — Fourniture et pose d'un robinet mitigeur";
                case "paragraph":
                  if (node) {
                    return "Description — référence, dimensions...";
                  } else {
                    return "Bloc texte — ajoutez des généralités, un titre...";
                  }
                case "lotText":
                  return "Bloc texte 2 : ajoutez des généralités, un titre...";
                default:
                  return "Description...";
              }
            },
          }),
          Paragraph.extend({
            priority: 1001,
          }),
          customDropCursor.configure({
            width: 4,
            color: "rgba(1,148,154,0.32)",
          }),
        ],

        editorProps: {
          handleDrop: (view, event, slice, moved) => {
            let ret = true;

            let pos = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });

            let node = pos && pos.inside >= 0 && view.state.doc.nodeAt(pos.inside);

            let disabled = node && node.type && (node.type.name === "lotHeading" || node.type.name === "docLot");

            if (moved && pos && !disabled) {
              ret = false;
            }

            return ret;
          },
        },
      });

      this.editor.on(
        "update",
        debounce(({ editor }) => this.syncDocument(editor.getJSON()), 500)
      );
    },
    modalImportShow() {
      this.modals.import.projectId = "";
      this.modals.import.lotId = "";
      this.modals.import.page = 0;
      this.modals.import.show = true;
      this.modals.import.selectedOuvrages = [];
    },
    modalImportClose() {
      this.modals.import.show = false;
      this.modals.import.projectId = "";
      this.modals.import.lotId = "";
      this.modals.import.page = 0;
      this.modals.import.selectedOuvrages = [];
    },
    /*modalImportFetchOuvrages() {
            try {
                const project = this.project;
                this.modals.import.ouvrages = JSON.parse(JSON.stringify(project.doc
                    .content.find(n => n.type === "docLot" && n.attrs.id === this.modals.import.lotId)
                    .content.find(n => n.type === "lotContent")
                    .content.filter(n => (n.type === "lotOuvrage" || n.type === "lotText"))))
                    .map(n => {
                        // cleanup location & qt fields
                        if (n.type === "lotOuvrage") {
                            n.attrs.location = "";
                            n.attrs.locations = [];
                            n.attrs.qt = "";
                        }
                        return n
                    });
            } catch (e) {
                this.modals.import.ouvrages = [];
            }

            this.modals.import.page = 1;
        },*/
    modalImportValidate() {
      // fetch ouvrages for project & lot
      const content = this.modals.import.selectedOuvrages;

      // import eventual content into current document
      if (content.length > 0) {
        toast.success("" + content.length + (content.length > 1 ? " ouvrages importés" : " ouvrage importé"));
        this.editor.commands.focus();

        this.editor.commands.insertContent(content);
        this.editor.commands.focus();
      } else {
        // todo popup message aucun ouvrage
        toast.info("Aucun ouvrage importé");
      }

      this.modalImportClose();
    },
    modalImportGetLotsForProject() {
      return this.project?.lots;
    },
    menuColorToggle() {
      this.menuColor.show = !this.menuColor.show;
    },
    beforeWindowUnload(e) {
      if (this.editor && this.leaveGuard) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {},
  watch: {
    editor: function (newValue, oldValue) {
      if (newValue && !oldValue && this.isStoreReady) {
        this.editor.chain().setContent(this.fetchFocusDoc()).run();
      }
    },
    currentFocus: function () {
      if (this.editor) {
        if (this.isStoreReady) {
          this.editor.chain().setContent(this.fetchFocusDoc()).focus("start").run();
        } else {
          this.editor.destroy();
          this.loadEditor();
        }
      } else {
        this.loadEditor();
      }
    },
    "localBiblio.name": function () {
      if (this.localBiblio.name !== this.biblio.name) {
        this.leaveGuard = true;
      }
    },
    "localBiblio.tags": function () {
      if (JSON.stringify(this.localBiblio.tags) !== JSON.stringify(this.biblio.tags.map((t) => t.id))) {
        this.leaveGuard = true;
      }
    },
    biblio: function () {
      if (this.editor) {
        if (this.isStoreReady) {
          this.editor.chain().setContent(this.fetchFocusDoc()).focus("start").run();
        } else {
          this.editor.off("update");
          this.editor.destroy();
          this.loadEditor();
        }
      } else {
        this.loadEditor();
      }
    },
  },
  computed: {
    isStoreReady() {
      return !!this?.localBiblio?.document;
    },
    isModalActive() {
      return kalmStore.state.display.profileToggled || Object.values(this.modals).some((m) => m.show);
    },
    currentFocus() {
      return this.$route.params.id;
    },
    currentProject() {
      return this.biblio;
    },
    getProjects() {
      return kalmStore.state.description.projects.filter((p) => p.id !== this.currentFocus);
    },
    isDescriptionDisplayModeCollapsed: {
      get() {
        return kalmStore.state.display.descriptionMode === "collapsed";
      },
      set(newValue) {
        const mode = newValue ? "collapsed" : "full";
        kalmStore.commit("updateDescriptionDisplayMode", mode);
      },
    },
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
    document.removeEventListener("keydown", this._keyListener);
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  img {
    max-width: 80%;
    height: auto;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }
}
</style>

<style>
.tooltip {
  z-index: 10000;
}

/* Removes the arrows from number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ProseMirror h4.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror h4 + p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@media print {
  .tooltip {
    display: none !important;
    visibility: hidden !important;
  }
}
</style>

<style scoped>
.editor {
  border-radius: 20px;
  margin-top: 40px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0 20px 30px; /*0 0px 40px 5px rgba(0, 0, 0, 0.2);*/
  background-color: white;
}

/*noinspection CssInvalidPropertyValue*/
.editor-buttons-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 180;
}

.drag-handle {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  right: 50px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProseMirror-focused:focus {
  outline: none !important;
}

.ProseMirror:focus {
  outline: none !important;
}

.ProseMirror-focused:focus-visible {
  outline: none !important;
}

.ProseMirror:focus-visible {
  outline: none !important;
}

.floating-menu {
  display: flex;
  padding: 0.2rem;
  border-radius: 0.5rem;
  z-index: 100 !important;
}

.floating-menu button {
  position: relative;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
  z-index: 100 !important;
}

.floating-menu button:hover,
.floating-menu button.is-active {
  opacity: 1;
}

.editor-toolbar {
  background-color: #195d5b;
  opacity: 0.95;
  backdrop-filter: blur(10px);
}

.editor-toolbar.toolbar-top {
  border-radius: 20px 20px 0 0;
  min-height: 50px;
}

.editor-toolbar.toolbar-sticky {
  border-radius: 25px;
  min-height: 40px;
}

@media print {
  .card {
    box-shadow: none;
  }

  .print-hidden {
    display: none !important;
  }

  .editor {
    box-shadow: none;
  }
}
</style>
