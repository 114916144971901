<template>
  <div class="row g-5 pb-3 p-10">
    <div class="d-flex">
      <h4 class="me-auto">Dossiers</h4>
      <button class="btn btn-primary" @click="onCreateFolderClick">+ Créer</button>
    </div>
    <div v-if="isLoading || folders.length" class="table-responsive col-12">
      <table class="table table-borderless align-middle">
        <thead>
          <tr>
            <th class="px-2 py-0 w-50px"></th>
            <th class="px-2 py-0 w-100"></th>
            <th class="px-2 py-0 min-w-150px"></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isLoading">
            <tr>
              <th class="text-center" colspan="3">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Chargement...</span>
                </div>
              </th>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="folder in folders"
              :key="folder.id"
              :class="{ 'bg-light': isDraggingFile === folder.id }"
              class="bg-hover-light"
              @click="openFolder(folder)"
              @dragleave="handleDragLeave"
              @dragover.prevent="handleDragOver($event, folder.id)"
              @drop.prevent="handleDrop"
            >
              <td class="px-0 py-3 rounded-start p-5">
                <div class="symbol symbol-55px ms-5 mt-1 me-5">
                  <span class="symbol-label bg-light-primary align-middle align-items-end">
                    <i class="bi bi-folder fs-1 align-middle align-self-center"></i>
                  </span>
                </div>
              </td>
              <td class="px-0">
                <p class="text-gray-800 fw-bolder fs-6 my-1">{{ folder.name }}</p>
                <span class="text-muted fw-bold d-block mt-1">{{ folder.url }}</span>
              </td>
              <td class="px-0 rounded-end">
                <EditButton @click="onEditFolderClick(folder)"></EditButton>
                <IconButton iconName="share-fill" @click="shareFile(folder)"></IconButton>
                <DeleteButton :disabled="!!folder.type" @click="onDeleteFolderClick(folder)"> </DeleteButton>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p class="text-center text-muted my-8">
        <span class="bi bi-folder me-2"></span>Aucun dossier. Cliquez sur créer pour en ajouter un<span
          class="ms-1 bi bi-arrow-up-right"
        ></span>
      </p>
    </div>
  </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import IconButton from "@/views/_core/components/IconButton.vue";
import { FILE_UPDATE, GET_FILES } from "../../../data/files_graphql";
export default {
  name: "FolderList",
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
    folders: {
      type: Array,
      require: true,
    },
    projectId: {
      type: String,
    },
    parentId: {
      type: String,
    },
    callWith: {
      type: String,
      default: "router",
    },
  },
  components: {
    DeleteButton,
    EditButton,
    IconButton,
  },
  data() {
    return {
      isDraggingFile: undefined,
    };
  },
  methods: {
    shareFile(folder) {
      this.$emit("onShare", folder);
    },
    onCreateFolderClick() {
      this.$emit("onCreate");
    },
    onEditFolderClick(folder) {
      this.$emit("onEdit", folder);
    },
    onDeleteFolderClick(folder) {
      this.$emit("onDelete", folder);
    },
    openFolder(folder) {
      this.$emit("onOpenFolder", folder);
    },
    handleDragOver(event, folderId) {
      if (event.dataTransfer.types.includes("kalm-file")) {
        event.dataTransfer.dropEffect = "copy";
        this.isDraggingFile = folderId;
      } else {
        this.isDraggingFile = undefined;
      }
    },
    handleDragLeave() {
      this.isDraggingFile = undefined;
    },
    handleDrop(event) {
      if (event.dataTransfer.types.includes("kalm-file")) {
        const data = JSON.parse(event.dataTransfer.getData("kalm-file"));
        const fileId = data.id;
        this.$apollo.mutate({
          mutation: FILE_UPDATE,
          variables: {
            fileId,
            parentId: this.isDraggingFile,
          },
          refetchQueries: [
            {
              query: GET_FILES,
              variables: {
                projectId: this.projectId,
                parentId: this.parentId,
              },
            },
          ],
        });
      }
      this.isDraggingFile = undefined;
    },
  },
};
</script>
