<template>
  <div class="card mb-4 border-left">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-start">
        <div>
          <span v-if="task?.numero || task?.groupTitle" class="font-monospace text-muted"
            >{{ task?.numero }}{{ task?.numero && task?.groupTitle ? " / " : "" }}{{ task?.groupTitle }}</span
          >
          <h5 class="card-title">{{ task.title }}</h5>
          <p class="card-text" style="white-space: pre-wrap">{{ task.description }}</p>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <input
            type="checkbox"
            class="form-check-input"
            @click="this.$emit('select', task)"
            :checked="this.selected"
          />
        </div>
      </div>

      <div v-if="task.locations?.length && viewType === 'lot'">
        <div class="mt-3">
          <p>
            <span class="me-2">Emplacement :</span>
            <span class="badge badge-light-primary me-1" v-for="location in task.locations" :key="location.id">{{
              location.name
            }}</span>
          </p>
        </div>
      </div>
      <div v-if="viewType === 'location'">
        <div class="mt-2" v-if="task?.lot?.name">
          <p>
            <span class="me-2">Lot :</span><span class="badge badge-light-primary me-1">{{ task?.lot?.name }} </span>
          </p>
        </div>
      </div>
      <div>
        <div class="mt-3">
          <p>
            <span class="me-2">Responsable :</span>
            <span v-if="!task.assignedCompany?.id" class="">Aucun</span>
            <span v-else-if="task.assignedCompany?.id === '00000000-0000-0000-0000-000000000000'" class="">Tous</span>
            <span v-else class="badge badge-light-primary me-1">{{ task.assignedCompany?.name }}</span>
          </p>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex">
          <CommentsCounter :taskId="task.id" @click="openComments"></CommentsCounter>
          <FileCounterForTask :taskId="task.id" @click="openFiles"></FileCounterForTask>
        </div>
        <div class="ms-auto d-flex">
          <StatusSelect v-model="this.taskStatus" :useColors="true"></StatusSelect>
          <EditButton @click="editTask"></EditButton>
          <DuplicateButton @click="duplicateTask"></DuplicateButton>
          <DeleteButton @click="deleteTask"></DeleteButton>
        </div>
      </div>
    </div>
    <CommentsListModal v-if="showCommentsModal" :taskId="task.id" @close="closeComments"></CommentsListModal>
    <FilesForTaskModal v-if="showFilesModal" :taskId="task.id" @close="closeFiles"></FilesForTaskModal>
  </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import DuplicateButton from "@/views/_core/components/DuplicateButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import CommentsCounter from "@/views/comments/presentation/comments-counter/CommentsCounter.vue";
import CommentsListModal from "@/views/comments/presentation/comments/CommentsListModal.vue";
import FileCounterForTask from "@/views/documents/presentation/file-counter-for-task/FileCounterForTask.vue";
import FilesForTaskModal from "@/views/documents/presentation/file-list-modal/FilesForTaskModal.vue";
import StatusSelect from "@/views/status/presentation/status-select/StatusSelect.vue";
import { UPDATE_TASK } from "@/views/tasks/data/tasks_graphql";

export default {
  name: "TaskCard",
  props: {
    task: {
      type: Object,
      required: true,
    },
    viewType: String,
    selected: Boolean,
  },
  components: {
    DuplicateButton,
    StatusSelect,
    CommentsListModal,
    FilesForTaskModal,
    CommentsCounter,
    FileCounterForTask,
    DeleteButton,
    EditButton,
  },
  data() {
    return {
      showCommentsModal: false,
      showFilesModal: false,
    };
  },
  methods: {
    deleteTask() {
      this.$emit("delete");
    },
    editTask() {
      this.$emit("edit");
    },
    duplicateTask() {
      this.$emit("duplicate");
    },
    openComments() {
      this.showCommentsModal = true;
    },
    closeComments() {
      this.showCommentsModal = false;
    },
    openFiles() {
      this.showFilesModal = true;
    },
    closeFiles() {
      this.showFilesModal = false;
    },
  },
  computed: {
    taskStatus: {
      get() {
        return this.task.status?.id;
      },
      set(value) {
        this.$apollo.mutate({
          mutation: UPDATE_TASK,
          variables: {
            id: this.task.id,
            input: {
              statusId: value,
              projectId: this.task.project?.id,
              lotId: this.task.lot?.id,
            },
          },
        });
      },
    },
    getColor() {
      return this.task?.color || "white";
    },
  },
};
</script>

<style scoped>
.border-left {
  border-left: 5px solid v-bind(getColor);
}
</style>
