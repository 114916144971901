<template>
  <div class="text-center">
    <img alt="No Todo" :src="image" class="img-fluid w-25" />
    <h3 class="card-title my-8">
      <span class="card-label fw-bolder text-dark fs-3">{{ message }}</span>
    </h3>
    <a class="btn btn-primary fw-bolder" @click="emitAdd">{{ buttonMessage }}</a>
  </div>
</template>

<script>
export default {
  name: "TodoEmptyState",
  props: {
    message: {
      type: String,
      default: "Cette todo est vide",
    },
    buttonMessage: {
      type: String,
      default: "Ajouter un élément",
    },
    image: {
      type: String,
      default: "/assets/media/svg/empty-states/empty-todo.svg",
    },
  },
  methods: {
    emitAdd() {
      this.$emit("add");
    },
  },
};
</script>
