<template>
  <div id="print-header-main" class="mb-5 d-print-flex flex-column align-content-center">
    <div class="row">
      <div v-if="currentUser.org.logo" class="col-3">
        <img :src="imageUrl" alt="Logo" fill="#01949A" style="max-width: 150px" />
      </div>
      <div :class="currentUser.org.logo ? 'col-9' : 'col-12'" class="row">
        <h1 :style="{ color: this.mainColor }" class="col-12 text-center text-dmserif mb-0">{{ pageTitle }}</h1>
        <p class="col-12 text-center text-gray-700 fs-3">
          Projet {{ project?.name }} — {{ new Date().toLocaleDateString("fr") }}
        </p>
        <div v-if="project?.address" class="col-12 justify-content-center row">
          <p v-if="project?.infoOpName" class="text-center text-gray-700 text-break col-12">
            <span class="fw-bolder text-gray-800">Opération : </span>{{ project?.infoOpName }}
          </p>
          <p v-if="project?.address" class="text-center text-gray-700 text-break col-12">
            <span class="fw-bolder text-gray-800">Adresse : </span>{{ project?.address }}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-10 row justify-content-center">
      <div v-if="this.moes?.length" class="col-5">
        <h3 :style="{ color: this.mainColor }" class="text-dmserif text-center">Maîtrise d'œuvre</h3>
        <template v-for="moe in this.moes" :key="moe.id">
          <div v-if="moe.contact?.infoLine1 || moe.contact?.infoLine2">
            <p class="mb-0 text-break fw-bolder">{{ moe.contact.infoLine1 }}</p>
            <p class="mb-0 text-break" style="white-space: pre-wrap">{{ moe.contact.infoLine2 }}</p>
          </div>
          <div v-else>
            <p class="mb-0 text-break fw-bolder">{{ currentUser.org.name }}</p>
            <p class="mb-0 text-break">{{ moe.contact.name }}</p>
            <p class="mb-0 text-break">{{ moe.contact.phone }}</p>
            <p class="text-break">{{ moe.contact.email }}</p>
          </div>
        </template>
      </div>
      <div class="col-1"></div>
      <div v-if="this.moas?.length" class="col-5">
        <h3 :style="{ color: this.mainColor }" class="text-dmserif text-center">Maîtrise d'ouvrage</h3>
        <template v-for="moa in this.moas" :key="moa.id">
          <div v-if="moa.contact?.infoLine1 || moa.contact?.infoLine2">
            <p class="mb-0 text-break fw-bolder">{{ moa.contact.infoLine1 }}</p>
            <p class="mb-0 text-break" style="white-space: pre-wrap">{{ moa.contact.infoLine2 }}</p>
          </div>
          <div v-else>
            <p v-if="moa.contact.type === 'CUSTOMER_PRO'" class="mb-0 text-break fw-bolder">
              {{ moa.contact.company }}
            </p>
            <p v-else class="mb-0 text-break fw-bolder">{{ moa.contact.name }}</p>
            <p class="mb-0 text-break">{{ moa.contact.phone }}</p>
            <p class="text-break">{{ moa.contact.email }}</p>
          </div>
        </template>
      </div>
    </div>
    <h2 v-if="sectionTitle" :style="{ color: this.mainColor }" class="text-center mt-5 mb-0 text-dmserif">
      {{ sectionTitle }}
    </h2>
    <p v-if="sectionText" class="text-center text-gray-700 pt-5">{{ sectionText }}</p>
  </div>
</template>

<script>
import kalmStore from "@/store";
import { CURRENT_USER_QUERY, GET_PROJECT_BY_ID } from "@/graphql/graphql";

export default {
  name: "PrintHeader",
  apollo: {
    currentUser: CURRENT_USER_QUERY,
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.state.display.currentProjectFocus,
        };
      },
      skip() {
        return (
          !kalmStore.state.display.currentProjectFocus ||
          kalmStore.state.display.currentProjectFocus === 0 ||
          kalmStore.state.display.currentProjectFocus === "0"
        );
      },
      fetchPolicy: "cache-and-network",
    },
  },
  props: {
    pageTitle: {
      type: String,
      default: "",
      required: true,
    },
    sectionTitle: {
      type: String,
      default: "",
      required: true,
    },
    sectionText: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      currentUser: {
        org: {
          logo: null,
          persoColorMain: null,
          persoFontTitle: "Dm Serif Display",
        },
      },
      project: null,
    };
  },
  methods: {},
  computed: {
    mainColor() {
      return this.currentUser.org?.persoColorMain || "#195D5B";
    },
    moas() {
      return this.project?.contacts.filter((c) => c.role === "MOA");
    },
    moes() {
      return this.project?.contacts.filter((c) => c.role === "MOE");
    },
    imageUrl() {
      return (
        (this.currentUser.org.logo.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) + this.currentUser.org.logo
      );
    },
    customFont() {
      return this.currentUser.org?.persoFontTitle || "DM Serif Display";
    },
  },
};
</script>

<style scoped>
@media print {
  #print-header-main {
    display: block;
  }

  @page {
    /* margin box at bottom right showing page number */
    @bottom-right-corner {
      content: counter(page) "/" counter(pages);
      color: black;
      display: block;
      margin-bottom: 20px;
    }
  }
}

@media screen {
  #print-header-main {
    display: none;
    visibility: hidden;
  }
}

h1 {
  font-size: 26pt;
}

h2 {
  font-size: 20pt;
}

h3 {
  font-size: 16pt;
}
</style>

<style>
.text-dmserif {
  font-family: v-bind(customFont), serif;
}
</style>
