<template>
  <KalmModal modalTitle="Photos & pièces-jointes" @close="close">
    <template v-slot:body>
      <div class="mt-4 mb-10">
        <label>Ajouter une photo :</label>
        <input accept="*" class="form-control" multiple type="file" @change="handleFileChange" />
      </div>
      <div v-if="todoItem.files.length > 0">
        <p class="fw-bold text-gray-700">Photos et fichiers liés à la tâche :</p>
        <div v-if="!isLoading" class="d-row">
          <div v-for="file in todoItem.files" :key="file.id" class="position-relative d-inline-block m-3">
            <!-- Display an image if the file is an image -->
            <img
              v-if="isImage(file)"
              :src="buildFullUrl(file.url)"
              alt="Image non décrite inclue sur la tâche"
              class="rounded shadow shadow-xs"
              height="100px"
            />

            <!-- Display a file icon if the file is not an image -->
            <div
              v-else
              class="d-flex flex-column align-items-center justify-content-center rounded shadow shadow-xs h-100px text-center text-muted"
            >
              <i class="bi bi-file-earmark-text fs-1"></i>
              <p class="text-truncate mt-2 mb-0 w-75">{{ file.name }}</p>
            </div>

            <!-- Delete button positioned at the top-right corner of each card -->
            <button
              class="btn btn-icon btn-sm btn-primary position-absolute"
              style="top: -10px; right: -10px"
              @click="removeFileFromTodoItem(file.id)"
            >
              <span class="bi bi-x"></span>
            </button>
          </div>
        </div>
        <div v-else class="mt-4 mb-10 absolute">
          <KalmLoader />
        </div>
      </div>
      <div v-else class="text-center text-muted">
        <p>Aucun fichier liée à cette tâche</p>
      </div>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import KalmLoader from "@/views/_core/components/Loader.vue";
import { FILE_UPLOAD } from "@/views/documents/data/files_graphql";
import { GET_TODO_ITEM_BY_ID, UNASSIGN_TODO_ITEM } from "@/views/todos/data/todos_graphql";

export default {
  name: "FilesForTodoItemModal",
  apollo: {
    todoItem: {
      query: GET_TODO_ITEM_BY_ID,
      variables() {
        return {
          todoItemId: this.todoItemId,
        };
      },
      skip() {
        return !this.todoItemId;
      },
    },
  },
  data() {
    return {
      todoItem: {
        files: [],
      },
      isLoading: false,
    };
  },
  props: {
    todoItemId: {
      type: String,
      required: true,
    },
  },
  components: {
    KalmModal,
    KalmLoader,
  },
  methods: {
    isImage(file) {
      return (
        file.url.endsWith(".png") ||
        file.url.endsWith(".jpg") ||
        file.url.endsWith(".jpeg") ||
        file.url.endsWith(".gif")
      );
    },
    removeFileFromTodoItem(fileId) {
      this.$apollo.mutate({
        mutation: UNASSIGN_TODO_ITEM,
        variables: {
          fileId: fileId,
          todoItemToRemoveId: this.todoItemId,
        },
        context: {
          hasUpload: true,
        },
        refetchQueries: [
          {
            query: GET_TODO_ITEM_BY_ID,
            variables: {
              todoItemId: this.todoItemId,
            },
            skip() {
              return !this.todoItemId;
            },
          },
        ],
      });
    },
    close() {
      this.$emit("close");
    },
    buildFullUrl(url) {
      if (url.startsWith("http")) {
        return url;
      }
      return process.env.VUE_APP_URL_BACK + url;
    },
    openFileExplorer() {
      // Trigger the click event on the hidden file input
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      this.isLoading = true;
      const selectedFiles = event.target.files;
      for (const file of selectedFiles) {
        try {
          await this.$apollo.mutate({
            mutation: FILE_UPLOAD,
            variables: {
              file,
              projectId: this.projectId,
              todoItemId: this.todoItemId,
              type: "MONITORING",
            },
            context: {
              hasUpload: true,
            },
            refetchQueries: [
              {
                query: GET_TODO_ITEM_BY_ID,
                variables: {
                  todoItemId: this.todoItemId,
                },
                skip() {
                  return !this.todoItemId;
                },
              },
            ],
          });
        } catch (error) {
          console.error("File upload error:", error);
        }
      }
      this.isLoading = false;
    },
  },
  computed: {
    projectId() {
      return this.$store.getters.currentProjectFocus;
    },
  },
};
</script>
