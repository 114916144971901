<template>
  <div class="h-100 p-10">
    <div class="row g-5">
      <IconButton v-if="parentId && folder?.parent?.id" iconName="arrow-left" @click="openParentFolder"></IconButton>
      <div class="card col-12">
        <div class="row g-5 pb-3 p-10">
          <div v-if="getFiles().length" class="table-responsive col-12">
            <table class="table table-borderless align-middle">
              <thead>
                <tr>
                  <th class="px-2 py-0 w-50px"></th>
                  <th class="px-2 py-0 w-100"></th>
                  <th class="px-2 py-0 min-w-100px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file in getFiles()" :key="file.id" class="bg-hover-light cursor-pointer">
                  <td class="px-0 py-3 rounded-start p-5">
                    <div class="symbol symbol-55px ms-5 mt-1 me-5">
                      <span class="symbol-label bg-light-primary align-middle align-items-end">
                        <i class="fs-1 align-middle align-self-center" :class="getFileIcon(file)"></i>
                      </span>
                    </div>
                  </td>
                  <td class="px-0">
                    <p class="text-gray-800 fw-bolder fs-6 my-1">{{ file.name }}</p>
                  </td>
                  <td class="px-0 rounded-end">
                    <IconButton v-if="!file.isDirectory" iconName="download" @click="downloadFile(file)"></IconButton>
                    <IconButton iconName="arrow-up-right" @click="onClickFile(file)"> </IconButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "@/views/_core/components/IconButton.vue";
import { GET_FILES_BY_LINK, GET_SUB_FOLDER_FILES_BY_LINK, GET_FOLDER_BY_LINK } from "../../data/files_graphql";
import { LINK_EVENT_CREATE } from "../../data/link-events_graphql";
import { getFileIconClass } from "../../helpers/documents.helpers";

export default {
  name: "FilesByLinkPage",
  components: {
    IconButton,
  },
  apollo: {
    filesByLink: {
      query: GET_FILES_BY_LINK,
      variables() {
        return { linkId: this.linkId };
      },
      skip() {
        return !this.linkId || this.parentId;
      },
      fetchPolicy: "cache-and-network",
    },
    subFolderFilesByLink: {
      query: GET_SUB_FOLDER_FILES_BY_LINK,
      variables() {
        return {
          linkId: this.linkId,
          parentId: this.parentId,
        };
      },
      skip() {
        return !this.linkId || !this.parentId;
      },
      fetchPolicy: "cache-and-network",
    },
    folder: {
      query: GET_FOLDER_BY_LINK,
      variables() {
        return {
          linkId: this.linkId,
          folderId: this.parentId,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.parentId;
      },
    },
  },
  data() {
    return {
      linkId: "",
      parentId: "",
      filesByLink: [],
      subFolderFilesByLink: [],
    };
  },
  async mounted() {
    this.linkId = this.$route.params.linkId;
    this.parentId = this.$route.params.parentId;
  },
  watch: {
    $route(to) {
      this.parentId = to.params.parentId || undefined;
    },
    parentId() {
      this.filesByLink = [];
      this.subFolderFilesByLink = [];
      this.folder = undefined;
      if (this.parentId) {
        this.$apollo.queries.folder.refetch();
        this.$apollo.queries.subFolderFilesByLink.refetch();
      } else {
        this.$apollo.queries.filesByLink.refetch();
      }
    },
  },
  methods: {
    getFiles() {
      return this.subFolderFilesByLink.length ? this.subFolderFilesByLink : this.filesByLink;
    },
    getFileUrl(file) {
      if (file.url.startsWith("http")) {
        return file.url;
      }
      return process.env.VUE_APP_URL_BACK + file.url;
    },
    getFileIcon(file) {
      if (file.isDirectory) {
        return "bi bi-folder";
      }
      return getFileIconClass(file);
    },
    onClickFile(file) {
      if (file.isDirectory) {
        this.openFolder(file);
      } else {
        this.openFileInNewTab(file);
      }
    },
    openFolder(file) {
      this.$router.push({
        name: "shared.subfiles",
        params: {
          linkId: this.linkId,
          parentId: file.id,
        },
      });
    },
    openParentFolder() {
      if (this.folder?.parent?.id) {
        this.$router.push({
          name: "shared.subfiles",
          params: {
            linkId: this.linkId,
            parentId: this.folder.parent.id,
          },
        });
      } else {
        this.$router.push({
          name: "shared.files",
          params: {
            linkId: this.linkId,
          },
        });
      }
    },
    openFileInNewTab(file) {
      window.open(this.getFileUrl(file), "_blank");
    },
    downloadFile(file) {
      fetch(this.getFileUrl(file))
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "download";
          link.click();
        })
        .catch(console.error);

      this.$apollo.mutate({
        mutation: LINK_EVENT_CREATE,
        variables: {
          linkId: this.linkId,
          fileId: file.id,
          name: "download",
        },
      });
    },
  },
};
</script>
