<template>
  <div>
    <label class="form-label" for="content">Nom</label>
    <input id="name" v-model="title" class="form-control" type="text" />
    <div class="float-end mt-2 pt-1">
      <button
        v-if="!isSaving && !readOnly"
        :disabled="!canSave()"
        class="btn btn-primary btn-sm"
        type="button"
        @click="save"
      >
        {{ this.buttonName }}
      </button>

      <LoadingButton v-if="isSaving"></LoadingButton>
    </div>
  </div>
</template>

<script>
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import { GET_CANEVA_BY_ID_QUERY } from "@/views/canevas/data/canevas_graphql";
import { CREATE_TODO, GET_TODOS, UPDATE_TODO } from "../../data/todos_graphql";

export default {
  name: "TodoForm",
  props: {
    todo: Object,
    projectId: {
      type: String,
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filterAssignedTo: {
      type: Array,
    },
    filterProject: {
      type: Array,
    },
    lastOrder: Number,
    assignedToCurrentUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    canevaId: {
      type: String,
      required: false,
    },
    buttonName: {
      type: String,
      required: false,
      default: "Ajouter",
    },
  },
  data() {
    return {
      title: "",
      isSaving: false,
    };
  },
  created() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (this.todo) {
        this.title = this.todo.title;
      }
    },
    canSave() {
      return this.title?.length;
    },
    async save() {
      this.isSaving = true;
      if (this.todo) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_TODO,
            variables: {
              id: this.todo.id,
              title: this.title,
            },
            refetchQueries: [
              {
                query: GET_TODOS,
                variables: {
                  projectId: this.projectId,
                  assignedToCurrentUser: this.assignedToCurrentUser,
                  filterAssignedTo: this.filterAssignedTo,
                  filterProject: this.filterProject,
                },
                skip: !this.projectId,
              },
              {
                query: GET_CANEVA_BY_ID_QUERY,
                variables: {
                  id: this.canevaId,
                },
                skip: !this.canevaId,
              },
            ],
          });
        } catch (error) {
          console.error("Update todo error:", error);
        }
      } else {
        try {
          await this.$apollo.mutate({
            mutation: CREATE_TODO,
            variables: {
              title: this.title,
              projectId: this.projectId,
              order: (this.lastOrder || 0) + 1,
              canevaId: this.canevaId,
            },
            refetchQueries: [
              {
                query: GET_TODOS,
                variables: {
                  projectId: this.projectId,
                  assignedToCurrentUser: this.assignedToCurrentUser,
                  filterAssignedTo: this.filterAssignedTo,
                  filterProject: this.filterProject,
                },
                skip: !this.projectId,
              },
              {
                query: GET_CANEVA_BY_ID_QUERY,
                variables: {
                  id: this.canevaId,
                },
                skip: !this.canevaId,
              },
            ],
          });
          this.title = "";
        } catch (error) {
          console.error("Create todo error:", error);
        }
      }
      this.isSaving = false;
      this.$emit("close");
      // Scroll smoothly to the bottom of the page after 100ms
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    },
  },
  components: { LoadingButton },
};
</script>
