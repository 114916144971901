<template>
  <div class="mt-4">
    <div class="d-flex gap-3 align-items-center">
      <input @keyup.enter="save" v-model="name" type="text" class="form-control" id="name" placeholder="Nom de la tâche" />
      <IconButton iconName="check" @click="save" />
    </div>
  </div>
</template>

<script>
import IconButton from '@/views/_core/components/IconButton.vue';

export default {
  name: "TodoSubItemForm",
  props: {
    todoItem: Object,
  },
  components: {
    IconButton
  },
  data() {
    return {
      name: "",
    };
  },
  created() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (this.todoItem) {
        this.name = this.todoItem.name;
      }
    },
    canSave() {
      return this.name?.length;
    },
    async save() {
      this.$emit("save", {
        name: this.name,
      });
      this.name = "";
    },
  },
};
</script>
