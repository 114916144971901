<template>
  <KalmModal modalTitle="Historique des versions" @close="close">
    <template v-slot:body>
      <div v-for="version in file.fileVersions" :key="version.id" class="mt-4 form-check">
        <a :href="version.url" target="_blank">Version du {{ new Date(version.createdAt).toLocaleString() }}</a>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";

export default {
  name: "FileHistoryModal",
  components: {
    KalmModal,
  },
  props: {
    file: {
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
