<template>
  <div class="me-2">
    <label v-if="title" class="col-form-label">{{ title }}</label>
    <select
      v-model="selectedStatusIdInternal"
      class="form-select form-select-solid form-select-sm"
      :style="useColors ? `color: ${getColorForStatusId(selectedStatusIdInternal)};` : ''"
    >
      <option v-for="status in statusOptions" :key="status.id" :value="status.id">
        {{ status.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { ALL_STATUSES_QUERY } from "../../data/status_graphql";

export default {
  name: "StatusSelect",
  props: {
    title: String,
    modelValue: {
      type: String,
    },
    useColors: {
      type: Boolean,
      default: false,
    },
    defaultNullStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statuses: [],
    };
  },
  computed: {
    selectedStatusIdInternal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log(value);
        this.$emit("update:modelValue", value);
      },
    },
    statusOptions() {
      if (this.defaultNullStatus) {
        return [{ id: null, name: "" }, ...this.statuses];
      }
      return this.statuses;
    },
  },
  methods: {
    getColorForStatusId(statusId) {
      return this.statuses.find((s) => s.id === statusId)?.color;
    },
  },
  apollo: {
    statuses: {
      query: ALL_STATUSES_QUERY,
      result({ data }) {
        if (!this.selectedStatusIdInternal && !this.defaultNullStatus) {
          this.selectedStatusIdInternal = data.statuses.find((s) => s.isDefault)?.id;
        }
      },
    },
  },
};
</script>
