<template>
  <div class="row g-3 print-hidden">
    <div class="col-12">
      <div v-if="showHeader" class="d-flex align-items-center justify-content-between">
        <h3 class="card-title align-items-start flex-column pb-3">
          <span v-if="!assignedToCurrentUser" class="card-label fw-bolder text-dark fs-2">Todos</span>
          <span v-if="assignedToCurrentUser" class="card-label fw-bolder text-dark fs-2">Mes Tâches</span>
        </h3>
        <div v-if="!assignedToCurrentUser && showFilters" class="card-toolbar ms-3">
          <ul class="nav nav-pills nav-pills-sm nav-light">
            <li class="d-flex ms-auto justify-content-end">
              <div class="nav-link form-check form-switch">
                <label
                  v-tooltip:bottom="'Masquer les tâches complétées'"
                  class="form-check-label"
                  for="switch-hide-completed"
                >
                  <span class="bi bi-eye-slash-fill"></span>
                </label>
                <input
                  id="switch-hide-completed"
                  v-model="hideCompleted"
                  class="form-check-input"
                  role="switch"
                  type="checkbox"
                />
              </div>
            </li>
            <li class="d-flex ms-auto justify-content-end mb-2">
              <div class="me-2">
                <UserMultiSelect v-model="filterAssignedTo" :options="this.userFilterOptions"></UserMultiSelect>
              </div>
              <div class="me-2">
                <ProjectMultiSelect v-model="filterProject" :options="this.projectFilterOptions"></ProjectMultiSelect>
              </div>
            </li>
            <li class="d-flex ms-auto justify-content-end mb-2">
              <button class="nav-link btn btn-secondary btn-sm fw-bolder me-2" @click="canevaPath">
                Voir les canevas
              </button>
              <button class="nav-link btn btn-primary btn-sm fw-bolder me-2" @click="addTodo">Ajouter une Todo</button>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="this.$apollo.queries.todosByProject.loading" class="w-100 d-flex justify-content-center">
        <!-- spinner, not kalm loader -->
        <div class="my-12 spinner spinner-border text-primary text-center"></div>
      </div>
      <TodosList
        :assignedToCurrentUser="assignedToCurrentUser"
        :canevaId="null"
        :emptyStateType="assignedToCurrentUser ? 'card' : 'image'"
        :filterAssignedTo="filterAssignedTo"
        :filterProject="filterProject"
        :hideCompleted="hideCompleted"
        :initialItems="todosByProject"
        :loading="loading"
        :projectId="projectId"
        :show-all-buttons="!reducedMode"
        @addTodo="addTodo"
      />
    </div>
    <TodoFormModal
      v-if="showTodoModal"
      :assignedToCurrentUser="assignedToCurrentUser"
      :canevaId="null"
      :filterAssignedTo="filterAssignedTo"
      :filterProject="filterProject"
      :last-order="getLastOrder()"
      :projectId="projectId"
      @close="reset"
    />
  </div>
</template>

<script>
import { ALL_PROJECTS_SIMPLE_QUERY, CURRENT_USER_QUERY } from "@/graphql/graphql";
import ProjectMultiSelect from "@/views/project/ProjectMultiSelect.vue";
import UserMultiSelect from "@/views/users/presentation/user-select/UserMultiSelect.vue";
import { GET_TODOS } from "../../data/todos_graphql";
import TodoFormModal from "../todo-form/TodoFormModal.vue";
import TodosList from "./components/TodosList.vue";
export default {
  name: "TodosPage",
  components: {
    UserMultiSelect,
    ProjectMultiSelect,
    TodoFormModal,
    TodosList,
  },
  props: {
    // Used to show own user todos on the dashboard
    assignedToCurrentUser: {
      type: Boolean,
      default: false,
    },
    allProjects: {
      type: Boolean,
      default: false,
    },
    reducedMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultHideCompleted: {
      type: Boolean,
      default: null,
    },
  },
  async mounted() {
    this.projectId = this.$route.params.id;
    this.filterProject = [this.projectId || "00000000-0000-0000-0000-000000000000"];
  },
  data() {
    return {
      projectId: undefined,
      todosByProject: [],
      showTodoModal: false,
      filterAssignedTo: [],
      filterProject: [],
      projects: [],
      currentUser: {
        id: null,
        org: {
          team: [],
        },
      },
      hideCompletedValue: null,
    };
  },
  watch: {
    $route(to) {
      this.projectId = to.params.id;
      this.filterProject = this.allProjects ? [] : [this.projectId || "00000000-0000-0000-0000-000000000000"];
    },
  },
  methods: {
    reset() {
      this.showTodoModal = false;
    },
    addTodo() {
      this.showTodoModal = true;
    },
    getLastOrder() {
      if (!this.todosByProject.length) {
        return 0;
      }
      return this.todosByProject[this.todosByProject.length - 1].order;
    },
    canevaPath() {
      this.$router.push({ name: "canevas" });
    },
  },
  computed: {
    hideCompleted: {
      get() {
        if (this.hideCompletedValue === null) {
          if (this.defaultHideCompleted !== null) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.hideCompletedValue = this.defaultHideCompleted;
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.hideCompletedValue = false;
          }
        }
        return this.hideCompletedValue;
      },
      set(newValue) {
        this.hideCompletedValue = newValue;
      },
    },
    loading() {
      return this.$apollo.loading;
    },
    userFilterOptions() {
      return [
        { id: "00000000-0000-0000-0000-000000000000", lastName: "", firstName: "Non attribué" },
        ...this.currentUser.org.team,
      ];
    },
    projectFilterOptions() {
      return [
        { id: "00000000-0000-0000-0000-000000000000", name: "Todo d'agence" },
        ...this.projects.filter((p) => !p.archived),
      ];
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    projects: {
      query: ALL_PROJECTS_SIMPLE_QUERY,
    },
    todosByProject: {
      query: GET_TODOS,
      variables() {
        return {
          projectId: !this.filterProject?.length ? undefined : this.projectId,
          assignedToCurrentUser: this.assignedToCurrentUser,
          filterAssignedTo: this.filterAssignedTo,
          filterProject: this.allProjects ? null : this.filterProject,
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
