<template>
  <div class="row">
    <div class="col-12">
      <!-- simple alert -->
      <div
        class="mt-3 alert print-hidden bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row"
      >
        <div class="">
          <span class="bi bi-lightbulb text-primary me-4 fs-1 align-middle"></span>
        </div>
        <div class="d-flex flex-column pe-0 pe-sm-10">
          <h5 class="mb-1">Contribuez à la stratégie produit de Kalm !</h5>
          <p class="mb-1">
            Construire un produit avec nos utilisateurs est au cœur de notre ADN, alors on vous fait choisir ce sur quoi
            nous travaillerons en priorité pour l'année 2025. Note : nous n'avons pas inclus les améliorations sur le
            module budgétaire et sur la todo qui sont en cours.
          </p>
          <p class="mb-1">
            La connexion à l'outil de vote est automatique si vous êtes connectés à Kalm. Si l'outil vous demande de
            vous connecter, c'est probablement que vous avez une extension de blocage de cookies comme AdBlock -- dans
            ce cas, créez-vous un compte ou désactivez le blocage.
          </p>
        </div>
      </div>
    </div>
    <div v-if="this.currentUser.cannyToken === null">
      <!-- Loading -->
      <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    </div>
    <div>
      <div data-canny />
    </div>
  </div>
</template>

<script>
import { CURRENT_USER_QUERY } from "@/graphql/graphql";

export default {
  name: "ProjectsPage",
  apollo: {
    currentUser: CURRENT_USER_QUERY,
  },
  components: {},
  methods: {},
  computed: {},
  mounted() {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          (e.type = "text/javascript"),
            (e.async = !0),
            (e.src = "https://canny.io/sdk.js"),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ("function" != typeof w.Canny) {
        var c = function () {
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          "complete" === d.readyState
            ? l()
            : w.attachEvent
              ? w.attachEvent("onload", l)
              : w.addEventListener("load", l, !1);
      }
    })(window, document, "canny-jssdk", "script");

    // eslint-disable-next-line no-undef
    Canny("render", {
      boardToken: this.boardToken,
      basePath: "vote",
      ssoToken: this.currentUser.cannyToken,
      theme: "light",
    });
  },
  data() {
    return {
      boardToken: "ec365e6b-9731-4c79-2726-a1b7c989cb21",
      currentUser: {
        cannyToken: null,
      },
    };
  },
  watch: {
    "currentUser.cannyToken": function (newValue) {
      if (newValue) {
        // eslint-disable-next-line no-undef
      }
    },
  },
};
</script>

<style scoped></style>
