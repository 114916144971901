<template>
  <div @mouseleave="this.open = false">
    <div
      ref="input"
      :class="this.open ? 'rounded-bottom-0' : ''"
      :style="{
        backgroundImage: this.open
          ? 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB3aWR0aD0iMjVweCIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGlkPSJjcm9zcyIgZGF0YS1uYW1lPSJMaW5lIENvbG9yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGxpbmUtY29sb3IiPjxsaW5lIGlkPSJwcmltYXJ5IiB4MT0iMTkiIHkxPSIxOSIgeDI9IjUiIHkyPSI1IiBzdHlsZT0iZmlsbDogbm9uZTsgc3Ryb2tlOiAjN2U4Mjk5OyBzdHJva2UtbGluZWNhcDogcm91bmQ7IHN0cm9rZS1saW5lam9pbjogcm91bmQ7IHN0cm9rZS13aWR0aDogMjsiPjwvbGluZT48bGluZSBpZD0icHJpbWFyeS0yIiBkYXRhLW5hbWU9InByaW1hcnkiIHgxPSIxOSIgeTE9IjUiIHgyPSI1IiB5Mj0iMTkiIHN0eWxlPSJmaWxsOiBub25lOyBzdHJva2U6ICM3ZTgyOTk7IHN0cm9rZS1saW5lY2FwOiByb3VuZDsgc3Ryb2tlLWxpbmVqb2luOiByb3VuZDsgc3Ryb2tlLXdpZHRoOiAyOyI+PC9saW5lPjwvc3ZnPg==)'
          : '',
      }"
      class="form-select cursor-pointer fw-normal text-gray-800 py-1 fs-6 field-multilocation"
      @click="this.openClose"
    >
      <div
        v-for="tag in this.value"
        :key="tag"
        class="d-inline-block cursor-default bg-primary text-white px-3 m-1 rounded"
      >
        <span>{{ this.options.find((l) => l.id === tag)?.name }}</span>
        <i
          class="bi bi-x ms-2 text-white align-middle cursor-pointer"
          @click="removeValue($event, tag)"
          @touchend="removeValue($event, tag)"
        ></i>
      </div>
      <div v-if="this.value.length === 0" class="fs-6 py-1">Aucun emplacement sélectionné</div>
    </div>
    <div
      v-show="this.open"
      :style="`position: absolute; z-index: 10000 !important; width: ${this.multiselectWidth}px;`"
      class="p-0"
    >
      <ul
        ref="dropdown"
        class="w-100 list-unstyled bg-white rounded-bottom"
        style="border: 1px solid #e4e6ef; border-top: none; z-index: 200 !important"
      >
        <li class="p-2 input-group">
          <label class="input-group">Rechercher parmi les emplacements</label>
          <input
            ref="searchInput"
            v-model="this.multiselectSearch"
            class="form-control input-group rounded-start fw-normal"
            type="text"
          />
        </li>
        <li
          v-for="option in this.options
            .filter((o) => !this.value.includes(o.id))
            .filter((o) => o.name.toUpperCase().includes(this.multiselectSearch.toUpperCase()))"
          :key="option.id"
          class="p-2 text-gray-800 fs-6 px-5 py-3 bg-hover-secondary"
          style="z-index: 10000 !important"
          @click="addValue($event, option.id)"
        >
          {{ option.name }}
        </li>
        <li
          v-if="this.options.filter((o) => !this.value.includes(o.id)).length === 0"
          class="p-2 ps-5 bg-white fs-6 rounded-bottom"
          style="z-index: 10000 !important"
        >
          Tous les emplacements ont été ajoutés
        </li>
        <li
          v-if="this.multiselectSearch"
          class="p-2 text-primary fs-6 px-5 py-3 bg-hover-secondary rounded-bottom"
          style="z-index: 10000 !important"
          @click="this.createLocationFromSearch()"
        >
          + Créer l'emplacement : {{ this.multiselectSearch }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import kalmStore from "@/store";
import { MUTATION_PROJECT_CREATE_LOCATION } from "../../data/location_graphql";

export default {
  name: "LocationMultiselect",
  props: {
    modelValue: Array,
    options: {
      type: Array,
      required: true,
      reactivity: true,
    },
  },
  emits: ["update:modelValue"],
  beforeUnmount() {
    //window.removeEventListener('click', this.close)
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.$nextTick(() => {
      this.handleResize();
    });
  },
  data: () => ({
    open: false,
    multiselectWidth: 0,
    multiselectSearch: "",
  }),
  methods: {
    handleResize() {
      if (this.$refs.input) {
        this.multiselectWidth = this.$refs.input.offsetWidth;
      }
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
      event.stopPropagation();
      event.preventDefault();
    },
    async createLocationFromSearch() {
      if (this.multiselectSearch) {
        if (this.options.find((l) => l.name === this.multiselectSearch)) {
          this.multiselectSearch = this.multiselectSearch + " (1)";
        }
        try {
          const result = await this.$apollo.mutate({
            mutation: MUTATION_PROJECT_CREATE_LOCATION,
            variables: {
              newLocation: {
                name: this.multiselectSearch,
              },
              projectId: kalmStore.getters.currentProjectFocus,
            },
          });
          this.value = [
            this.this.value,
            ...result.data.projectLocationCreate.project.locations.find((l) => l.name === this.multiselectSearch).id,
          ].sort(this.sortValue);
          if (this.options.filter((o) => !this.value.includes(o.id)).length === 0) {
            this.open = false;
          }
        } catch (e) {
          console.error(e);
        }
        this.$emit("validate");
        this.multiselectSearch = "";
      }
    },
    openClose($event) {
      $event.preventDefault();
      console.log("prevented default");
      console.log("ouvert 1 :", this.open);
      this.open = !this.open;
      console.log("ouvert 2 :", this.open);
      if (this.open && this.options.filter((o) => !this.value.includes(o.id)).length !== 0) {
        console.log("ok - focus on search at next tick");
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    addValue(event, id) {
      this.value = [...this.value, id].sort(this.sortValue);
      if (this.options.filter((o) => !this.value.includes(o.id)).length === 0) {
        this.open = false;
      }
      event.stopPropagation();
      event.preventDefault();
    },
    removeValue($event, id) {
      console.log("remove value");
      this.value = [...this.value.filter((l) => l !== id)];
      $event.stopPropagation();
      $event.preventDefault();
    },
    sortValue(a, b) {
      if (this.options.find((l) => l.id === a)?.name < this.options.find((l) => l.id === b)?.name) {
        return -1;
      } else if (this.options.find((l) => l.id === a)?.name > this.options.find((l) => l.id === b)?.name) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  computed: {
    computeWidth() {
      return this.multiselectWidth;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    offsetWidth(newValue) {
      this.multiselectWidth = newValue;
    },
    /*open(newValue) {
          if(newValue) {
            document.addEventListener('click', this.close)
          }
          else {
            document.removeEventListener('click', this.close)
          }
        }*/
  },
};
</script>

<style scoped>
@media print {
  .print-hidden {
    display: none !important;
  }
}
</style>
